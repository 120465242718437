import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import data from "../data/in-the-press.json"

const Press = () => {
  return (
    <Layout>
      <SEO
        title="Press - Celantur"
        description="Celantur in the news, press releases and materials."
      />
      <section className="pt-8 pt-md-11 mb-5 mx-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
              <h1 className="display-3 font-weight-bold">Press</h1>
              <p className="lead">
                For press opportunities, please{" "}
                <Link to="/contact/" className="event-contact">
                  contact us
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="row">
            <div className="list-group list-group-flush col-12 pr-0">
              {data
                .filter(item => item.published)
                .sort((a, b) => b.date.localeCompare(a.date))
                .map(item => {
                  return (
                    <div className="list-group-item d-flex align-items-center" key={item.date}>
                      <div className="mr-auto">
                        <h2 className="mb-1">
                          <a href={item.link}>{item.title}</a>
                        </h2>

                        <p className="font-size-sm text-muted mb-0">
                        {item.date}, Source:{" "}
                          <a href={item.publisherUrl}>{item.publisher}</a>
                        </p>
                      </div>
                      <div className="badge badge-secondary-soft ml-4">
                        {item.type}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Press
